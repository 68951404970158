import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body shadow p-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = { class: "text-gray-800 fw-bold fs-6 m-0" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "col-4 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card bgi-no-repeat"]),
    style: _normalizeStyle([`background-image: url('/media/svg/shapes/${_ctx.background}')`, {"background-position":"right top","background-size":"30% auto","height":"100% !important"}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("span", { innerHTML: _ctx.title }, null, 8, _hoisted_5)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass(["fw-bolder fs-6", _ctx.colorNumber]),
            style: {"white-space":"nowrap"}
          }, _toDisplayString(_ctx.number), 3)
        ])
      ])
    ])
  ], 6))
}