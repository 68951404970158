import services from "../axios/dbManag";

export const delAnagraficaHp = (cod_fisc) => {
  return services.dbManag
    .post("/pre-tesseramento/anagrafiche/del", {
      cod_fisc,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const delRichiesteHp = (id, cod_fisc) => {
  return services.dbManag
    .post("/pre-tesseramento/richieste/tesseramento/del", {
      id,
      cod_fisc,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewRiepilogo = (anno) => {
  return services.dbManag
    .post("/pre-tesseramento/conteggi", {
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
