<template>
  <div class="mx-auto my-auto text-center" v-if="isLoading">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div class="row" v-if="!isLoading && !error">
    <div class="col-3">
      <WidgetPretesseramento
        widget-classes="card-xl-stretch"
        background=""
        title="Anagrafiche totali"
        :number="riepilogo.anagrafiche"
        description=""
        :colorNumber="'blueFit'"
      ></WidgetPretesseramento>
    </div>
    <div class="col-3">
      <WidgetPretesseramento
        widget-classes="card-xl-stretch"
        background=""
        title="Anagrafiche confermate"
        :number="riepilogo.anagrafiche_confermate"
        description=""
        :colorNumber="'text-success'"
      ></WidgetPretesseramento>
    </div>
    <div class="col-3">
      <WidgetPretesseramento
        widget-classes="card-xl-stretch"
        background=""
        title="Richieste di tesseramento totali "
        :number="riepilogo.richieste"
        description=""
        :colorNumber="'blueFit'"
      ></WidgetPretesseramento>
    </div>
    <div class="col-3">
      <WidgetPretesseramento
        widget-classes="card-xl-stretch"
        background=""
        title="Richieste di tesseramento confermate "
        :number="riepilogo.richieste_inoltrate"
        description=""
        :colorNumber="'text-success'"
      ></WidgetPretesseramento>
    </div>
  </div>
  <div v-if="!isLoading && error">
    <div class="text-gray-700 pt-3 fs-2 text-center">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </div>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
    >
      <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
        <i class="bi bi-house fs-2 text-gray-800"> </i
      ></router-link>
      <router-link v-else to="/bacheca-public">
        <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
    ></span>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import WidgetPretesseramento from "./WidgetPretesseramento.vue";
import isEnabled from "@/composables/isEnabled.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { viewRiepilogo } from "@/requests/PretesseramentoRequests";

export default defineComponent({
  name: "sintesi-economato",
  components: { WidgetPretesseramento },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Sintesi Economato");
    });
    const store = useStore();

    const anno = computed(() => store.getters.stagioneSelected);

    const isLoading = ref(false);
    const error = ref(false);

    const riepilogo = ref({});

    const viewSintesi = (anno) => {
      isLoading.value = true;
      viewRiepilogo(anno).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          riepilogo.value = res.data.results[0];
        } else {
          error.value = true;
        }
      });
    };
    viewSintesi(anno.value);

    watch(anno, () => {
      viewSintesi(anno.value);
    });

    return {
      anno,
      riepilogo,
      error,
      isLoading,
      isEnabled,
    };
  },
});
</script>

<style scoped>
.blueFit {
  color: #1b267e;
}
</style>
