<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-6">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            @click="esportaLista()"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
            filtrata
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        report_pretesseramento_list.length == 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="report_pretesseramento_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsReportPretesseramento"
        @current-change="setCurrentPageReportPretesseramento"
        @checkboxClicked="checkAll ? (checkAll = !checkAll) : (checkAll = true)"
      >
        <template v-slot:cell-data="{ row: data }"
          ><div class="">{{ data.data }}</div>
        </template>
        <template v-slot:cell-anagrafiche="{ row: data }"
          ><div class="">{{ data.anagrafiche }}</div>
        </template>
        <!-- <template v-slot:cell-email_valide="{ row: data }"
          ><div class="">{{ data.email_valide }}</div>
        </template> -->
        <template v-slot:cell-richieste_compilate="{ row: data }"
          ><div class="">{{ data.richieste_compilate }}</div>
        </template>
        <template v-slot:cell-richieste_inoltrate="{ row: data }"
          ><div class="">{{ data.richieste_inoltrate }}</div>
        </template>
        <template v-slot:cell-richieste_accettate="{ row: data }"
          ><div class="">{{ data.richieste_accettate }}</div>
        </template>
        <template v-slot:cell-richieste_rifiutate="{ row: data }"
          ><div class="">{{ data.richieste_rifiutate }}</div>
        </template>
        <template v-slot:cell-richieste_validetotali="{ row: data }"
          ><div class="">{{ data.richieste_validetotali }}</div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "TableReportPretesseramentoEconomato",
  components: { Datatable, Loading },
  emits: ["getReportPretesseramentoList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Data",
        key: "data",
        sortable: false,
      },
      {
        name: "Anagrafiche totali",
        key: "anagrafiche",
        sortable: false,
      },
      /* {
        name: "Email valide",
        key: "email_valide",
        sortable: false,
      }, */
      {
        name: "Tessere in compilazione",
        key: "richieste_compilate",
        sortable: false,
      },
      {
        name: "Tessere in attesa di approvazione",
        key: "richieste_inoltrate",
        sortable: false,
      },
      {
        name: "Tessere accettate dal circolo",
        key: "richieste_accettate",
        sortable: false,
      },
      {
        name: "Tessere rifiutate dal circolo",
        key: "richieste_rifiutate",
        sortable: false,
      },
      {
        name: "Totale tessere presso il circolo",
        key: "richieste_validetotali",
        sortable: false,
      },
    ]);
    const store = useStore();

    const setFetchRowsReportPretesseramento = (e) => {
      store.commit("setFetchRowsReportPretesseramento", e);
      emit("getReportPretesseramentoList");
    };
    const setCurrentPageReportPretesseramento = (page) => {
      store.commit("setCurrentPageReportPretesseramento", page);
      emit("getReportPretesseramentoList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnReportPretesseramento", columnName);
      store.commit("setSortOrderReportPretesseramento", order);
      emit("getReportPretesseramentoList");
    };

    const isLoading = ref(false);

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      isLoading,
      tableHeader,
      setFetchRowsReportPretesseramento,
      setCurrentPageReportPretesseramento,
      isEnabled,
      setSortOrderColumn,

      report_pretesseramento_list: computed(() =>
        store.getters.getStateFromName("resultsreport_pretesseramento_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedreport_pretesseramento_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordreport_pretesseramento_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusreport_pretesseramento_list")
      ),
      currentPage: computed(
        () => store.getters.currentPageReportPretesseramento
      ),
      rowsToSkip: computed(() => store.getters.rowsToSkipReportPretesseramento),
      fetchRows: computed(() => store.getters.fetchRowsReportPretesseramento),
      sortColumn: computed(() => store.getters.sortColumnReportPretesseramento),
      sortOrder: computed(() => store.getters.sortOrderReportPretesseramento),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      esportaLista,
    };
  },
};
</script>

<style scoped></style>
