<template>
  <div>
    <PretesseramentoDash /> <br />
    <FiltriPretesseramentoReport
      @getReportPretesseramentoList="searchReportPretesseramentoList"
      @resetFilters="resetFilters"
    />
    <TablePretesseramentoReport
      @getReportPretesseramentoList="getReportPretesseramentoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import PretesseramentoDash from "@/components/components-fit/report/PretesseramentoDash.vue";
import FiltriPretesseramentoReport from "@/components/components-fit/report/FiltriPretesseramentoReport.vue";
import TablePretesseramentoReport from "@/components/components-fit/report/TablePretesseramentoReport.vue";

export default defineComponent({
  name: "report-pretesseramento",
  components: {
    PretesseramentoDash,
    FiltriPretesseramentoReport,
    TablePretesseramentoReport,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Report Pretesseramento");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);
    const data_da = computed(() => store.getters.dataDaReportPretesseramento);
    const data_a = computed(() => store.getters.dataAReportPretesseramento);
    const rowsToSkip = computed(
      () => store.getters.rowsToSkipReportPretesseramento
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsReportPretesseramento
    );
    const sortColumn = computed(
      () => store.getters.sortColumnReportPretesseramento
    );
    const sortOrder = computed(
      () => store.getters.sortOrderReportPretesseramento
    );

    const getReportPretesseramentoList = () => {
      const localKeys = {
        anno: anno.value,
        data_da: data_da.value,
        data_a: data_a.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.REPORT_PRETESSERAMENTO_LIST,
        itemName: "report_pretesseramento_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersReportPretesseramento");
      getReportPretesseramentoList();
    };
    getReportPretesseramentoList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedreport_pretesseramento_list")
    );

    const searchReportPretesseramentoList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipReportPretesseramento");
      getReportPretesseramentoList();
    };

    return {
      getReportPretesseramentoList,
      resetFilters,
      searchReportPretesseramentoList,
    };
  },
});
</script>
