<template>
  <div>
    <div class="card shadow">
      <div class="p-4" @keyup.enter="$emit('getReportPretesseramentoList')">
        <div class="row">
          <div class="col-md-3">
            <Datepicker
              v-model="dataDaReportPretesseramento"
              @update:modelValue="setdataDaReportPretesseramento($event)"
              placeholder="Data da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
            />
          </div>
          <div class="col-md-3">
            <Datepicker
              v-model="dataAReportPretesseramento"
              @update:modelValue="setdataAReportPretesseramento($event)"
              placeholder="Data a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
            />
          </div>
          <div class="text-end col-md-6">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getReportPretesseramentoList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";

import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-esportazioni",
  emits: ["resetFilters", "getReportPretesseramentoList"],
  components: {
    //SelectInput,
    Datepicker,
  },
  setup(props, { emit }) {
    const store = useStore();

    const dataDaReportPretesseramento = computed(
      () => store.getters.dataDaReportPretesseramento
    );
    const setdataDaReportPretesseramento = (event) => {
      store.commit("setdataDaReportPretesseramento", event);
    };

    const dataAReportPretesseramento = computed(
      () => store.getters.dataAReportPretesseramento
    );
    const setdataAReportPretesseramento = (event) => {
      store.commit("setdataAReportPretesseramento", event);
    };

    const anno = computed(() => store.getters.stagioneSelected);

    watch(anno, () => {
      setdataDaReportPretesseramento(null);
      setdataAReportPretesseramento(null);
      emit("getReportPretesseramentoList");
    });

    return {
      dataDaReportPretesseramento,
      setdataDaReportPretesseramento,
      dataAReportPretesseramento,
      setdataAReportPretesseramento,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedreport_pretesseramento_list")
      ),
    };
  },
});
</script>
